import { axiosInstance } from "../../axios";

const GETSearchCustomers = "api/Company/AdminSearchCustomers?search=";
const GETByKey = "api/Company/GetByKey";
const POSTCreate = "api/Company/Save";
const POSTCreateCompany = "api/Company/CreateCompany";
const DELEETECustomer = "api/Company/Delete/";

const createCustomer = async function(customer) {
  try {
    const response = await axiosInstance.coreAPIInstance.post(POSTCreate, customer);
    return response.data;
  } catch (error) {
    return null;
  }
};

const createCompany = async function(customer) {
  try {
    const response = await axiosInstance.coreAPIInstance.post(POSTCreateCompany, customer);
    return response.data;
  } catch (error) {
    return null;
  }
};


const searchCustomers = async function() {
  try {
    const response = await axiosInstance.coreAPIInstance.get(GETSearchCustomers);
    return parseList(response);
  } catch (error) {
    return [];
  }
};

const getByKey = async function(searchkey) {
  try {
    const response = await axiosInstance.coreAPIInstance.get(`${GETByKey}/${searchkey}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const deleteCustomer = async function (id) {
  try {
      const response = await axiosInstance.coreAPIInstance.delete(DELEETECustomer + id);
      return response.data;
  } catch (error) {
    return null;
  }
}

const parseList = response => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

const parseError = error => {
  let stringMessage = error.response.data.Errors.join(" <br/> ");
  error.response.data.Message += "<br/>" + stringMessage;
  return error.response.data;
};

export const companyData = {
  searchCustomers,
  getByKey,
  createCustomer,
  createCompany,
  deleteCustomer
};
