<template>
<div>
    <div class="row page-title align-items-center">
        <div class="col-md-3 col-xl-6">
            <h4 class="mb-1 mt-0">Customers</h4>
        </div>
        <div class="col-md-9 col-xl-6 text-md-right">
            <div class="mt-4 mt-md-0">
                <button type="button" @click="editSelected(null)" class="btn btn-primary mb-3 mb-sm-0">
                    <i class="uil-plus mr-1"></i> New Customer
                </button>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-striped mb-0">
                    <thead>
                        <tr>
                            <th scope="col" class="width1"></th>
                            <th scope="col" class="width30">Name</th>
                            <th scope="col" class="width40">Address</th>
                            <th scope="col" class="width30">Contact</th>
                            <th scope="col" class="width1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tr, i) in companies" :key="i" :data="tr">
                            <td>
                                <button type="button" class="btn btn-primary" @click="editSelected(tr)">Edit</button>
                            </td>
                            <td>
                                {{ tr.name }}
                            </td>
                            <td>
                                {{ tr.addressLine1 }} {{ tr.addressLine2 }} {{ tr.suburb }} {{ tr.state }} {{ tr.postcode }}
                            </td>
                            <td>
                                {{ tr.phone }} / {{ tr.email }}
                            </td>
                            <td>
                                <button type="button" class="btn btn-danger" @click="deleteSelected(tr)">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    companyData
} from '@state/api/company'

export default {
    page: {
        title: 'Dashboard'
    },
    components: {},
    data() {
        return {
            companies: []
        }
    },
    async created() {
        await this.loadCompanies();
    },
    methods: {
        async loadCompanies() {
            this.companies = await companyData.searchCustomers();
        },
        editSelected(selectedCustomer) {
            if (selectedCustomer) {
                this.$router.push({
                    name: 'editcustomer',
                    params: {
                        id: selectedCustomer.keyId
                    }
                });
            } else {
                this.$router.push({
                    name: 'addCustomer'
                });
            }
        },
        async deleteSelected(selectedCustomer) {
            if (!confirm("Are you sure ?")) {
                return;
            }

            let apiResult = await companyData.deleteCustomer(selectedCustomer.keyId);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });

            await this.loadCompanies();
        }
    }
}
</script>
